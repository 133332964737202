.top-bar-wrapper {
    padding: 16px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100vw;
}

.top-bar-backdrop {
    background: white;
    padding: 8px 36px;
    border-radius: 64px;
    height: 64px;
    width: 100%;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.125);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2em;
    color: black;
    font-family: 'Noto Sans TC', sans-serif;
    font-weight: 700;
    max-width: 1280px;
}

.top-bar-backdrop .left {
    gap: 2em;
    display: flex;
    align-items: center;
}

.top-bar-backdrop .anchors {
    gap: 1.5em;
    display: flex;
    align-items: center;
}

.top-bar-backdrop .right {
    gap: 1em;
    display: flex;
    align-items: center;
}

.nav-button {
    text-decoration: unset;
    color: #4A372F;
}

.nav-button:hover {
    opacity: 0.8;
}

.top-bar-wrapper .logo {
    height: 100%;
    max-height: 36px;
    object-fit: contain;
}

@media only screen and (max-width: 896px) {
    .top-bar-backdrop .anchors {
        display: none;
    }
}