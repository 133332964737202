.banner-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.banner-wrapper {
    height: 50vh;
    max-height: 550px;
    width: 50vw;
}

.label {
    height: 36px;
    margin-top: 48px;
    margin-bottom: 8px;
}

.service-image {
    aspect-ratio: 1;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.slick-slider {
    max-width: 100vw;
}

/*===== Slider =====*/

.slider-controller {
    top: 0;
    right: 16px;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.slider-selected-circle {
    border-radius: 50%;
    height: 32px;
    width: 32px;
    border: #f2b400 2px solid;
    transition: all 0.25s ease-in-out;
}

.slider-controller-wrapper {
    background-color: black;
}

.slider-selected-wrapper {
    margin-right: 2px;
}

.slider-controller-dot-wrapper {
    padding: 16px;
    pointer-events: all;
    cursor: pointer;
}

.slider-controller-dot {
    height: 6px;
    width: 6px;
    background: #f2b400;
    border-radius: 50%;
}

.slick-prev:before,
.slick-next:before {
    color: #4A372F;
}